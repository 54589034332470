h1 {
    font-size: 26px;
    font-weight: bold;
}

h2 {
    font-size: 18px;
    font-weight: 300;
    color: #A1A5AD;
}
